import axios from "axios";

const BASE_URL = "https://us-central1-shoutout-c6349.cloudfunctions.net/shoutoutApp"
// const BASE_URL = "https://shoutout-mock.w3api.dev"

export const GetAllEventData = async () => {
    const result = (await axios(`${BASE_URL}/EventApp`)).data;
    console.log('All Event Data:', result)
    return result;
}
export const GetEventPolls = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventApp/${eventId}/Poll`)).data;
    return result;
}
export const GetEventSessions = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventApp/${eventId}/Session`)).data;
    return result;
}
export const GetEventSessionHalls = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventApp/${eventId}/SessionHall`)).data;
    return result;
}
export const GetEventUserData = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventUserData/${eventId}`)).data;
    return result;
}


export const GetDataWithEvent = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventWeb/${eventId}`)).data;
    return result;
}

export const PollResult = async (eventId) => {
    const result = (await axios(`${BASE_URL}/EventUserData/${eventId}`)).data;
    return result;
}

export const GetAllEventUserData = async () => {
    const result = (await axios(`${BASE_URL}/EventUserData`)).data;
    return result;
}
